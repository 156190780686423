import React, { ReactNode, Ref } from "react";
import * as SS from "@techstack/styled-system";
import { FieldState } from "./FieldContext";
import { FieldBorder, HelperText } from ".";
import FloatLabel from "./FloatLabel";
import { Box } from "../../box/Box";

export interface FieldProps extends SS.SpaceProps, SS.FlexboxProps {
    boxProps?: any;
    children?: ReactNode;
    error?: string | null;
    helperText?: string;
    icon?: ReactNode;
    alignIconToInput?: boolean;
    isValid?: boolean;
    label?: string;
    ref?: Ref<unknown>;
    htmlFor?: string;
    bg?: string;
}

interface Props extends FieldProps {
    fieldState: FieldState;
}

const Field = ({
   fieldState,
   children,
   helperText,
   label,
   error,
   icon,
   boxProps,
   htmlFor,
   alignIconToInput = false
   }:Props, ref: any) => {
    const { bg = "transparent", ...otherBoxProps } = boxProps;
    const doShrink = fieldState.hasValue || fieldState.focused;

    // Because a React.forwardRef component can't have default props.
    const defaultBoxProps = {
        my: 2,
    };

    return (
        <Box ref={ref} {...{ ...defaultBoxProps, ...otherBoxProps }}>
            <FieldBorder fieldState={fieldState} bg={bg}>
                {label && (
                    <FloatLabel htmlFor={htmlFor} doShrink={doShrink}>
                        {label}
                    </FloatLabel>
                )}
                {children}
                {icon && (
                    <Box
                        width="57px"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            position: "absolute",
                            right: 0,
                            top: alignIconToInput ? "15%" : 0,
                            zIndex: 2,
                        }}
                    >
                        {icon}
                    </Box>
                )}
            </FieldBorder>
            {error || helperText ? (
                <HelperText id={`${htmlFor}__helper-text`} fieldState={fieldState}>
                    {error || helperText}
                </HelperText>
            ) : null}
        </Box>
    );
};

export default React.forwardRef(Field);
