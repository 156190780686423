import React from "react";
import { Text } from "../../../text/Text";
import { Box } from "../../../box/Box";
import Product from "../../../../@types/interfaces/product/BuyBoxProduct";

interface Props {
    product: Product;
    isOnSale: boolean;
    saleText?: string;
}

const NonSubscriptionEligible:React.FC<Props> = ({ isOnSale, saleText, product }) => {
    return (
        <Box display="flex" border="2px solid" borderColor="grays.1" borderRadius="4px" px="11px" pt="5px" pb="9px" mt={2}>
            <Box flex="1">
                <Text fontSize="14px" color="grays.4" mb="10px">
                    Price
                </Text>
                <Text>
                    {isOnSale && (
                        <>
                            <p className="sr-only">Normally,</p>
                            <Text
                                display="inline-block"
                                textDecoration="line-through"
                                color="grays.4"
                                mr={1}
                            >
                                {product.price_msrp}
                            </Text>
                            <p className="sr-only">Your price today,</p>
                        </>
                    )}
                    <Text display="inline-block" fontWeight="bold">
                        {product.price}
                    </Text>
                    {isOnSale && (
                        <Text display="inline-block" fontWeight="bold" color="grays.4" ml={2}>
                            {saleText}
                        </Text>
                    )}
                </Text>
            </Box>
        </Box>
    );
};

export default NonSubscriptionEligible;
