import React from "react";
import { FiRefreshCw } from "react-icons/fi";
import { Box } from "../../../box/Box";
import { RadioButton, RadioGroup } from "../../../form/toggle/RadioGroup";
import { Text } from "../../../text/Text";
import SubscriptionInfoModal from "../../shared/SubscriptionInfoModal";
import Product from "../../../../@types/interfaces/product/BuyBoxProduct";
import CostPerServing from "../../shared/CostPerServing";
import CostPerCount from "../../shared/CostPerCount";
import ProductCategory from "../../../../@types/enums/ProductCategory";
import RiskFreeLinkText from "../../shared/RiskFreeLinkText";
import { SubscriptionDiscounts } from "../../../../services/SubscriptionDiscountCalculator";
import Icon from "../../../icon/Icon";

interface Props {
    doSubscribe: boolean;
    setDoSubscribe: (x: boolean) => void;
    subscriptionInterval: number;
    setSubscriptionInterval: (x: number) => void;
    product: Product;
    subscriptionDiscounts: SubscriptionDiscounts;
    saleText: string;
    isOnSale: boolean;
    subscriptionIntervalDefault: number;
}

const SubscriptionEligible = (
    {
        doSubscribe,
        setDoSubscribe,
        product,
        subscriptionDiscounts,
        saleText,
        isOnSale,
        subscriptionInterval,
    }: Props) => {

    const isSupplementsOrFoods = product.meta.category === ProductCategory.FOODS
        || product.meta.category === ProductCategory.SUPPLEMENTS;

    return (
        <Box>
            <RadioGroup
                labelPosition="left"
                value={doSubscribe ? "subscribe" : "single"}
                onChange={({target: {value}}) => {
                    setDoSubscribe(value === "subscribe");
                }}
            >
                <RadioButton value="subscribe">
                    <Box display="flex">
                        <Box flex="1">
                            <Box display="flex" alignItems="center" mb={1}>
                                <Text color="grays.4">Autoship &amp; Save</Text>
                                <SubscriptionInfoModal />
                            </Box>

                            <RiskFreeLinkText />

                            <p className="sr-only">Normally,</p>
                            <Text display="inline-block" textDecoration="line-through" color="grays.4">
                                {product.price_msrp}
                            </Text>
                            <p className="sr-only">Your price today,</p>
                            <Text display="inline-block" fontWeight="bold" ml={1}>
                                {subscriptionDiscounts.applies
                                    ? `$${subscriptionDiscounts.adjustedPrice.toFixed(2)}`
                                    : product.price}
                            </Text>

                            <Text display="inline-block" fontWeight="bold" color="grays.4" ml={2}>
                                {subscriptionDiscounts.applies
                                    ? `${subscriptionDiscounts.percent}% off`
                                    : saleText}
                            </Text>

                            <Box display="flex" alignItems="flex-start" mt={1}>
                                <Icon icon={FiRefreshCw} size={15} color="grays.5" mr={1} flexShrink={0}/>
                                <Text display="block" fontSize={1} color="grays.5">
                                    Autoships every {subscriptionInterval} days
                                </Text>
                            </Box>

                            {isSupplementsOrFoods && (
                                <>
                                    <CostPerServing
                                        price={subscriptionDiscounts.adjustedPrice.toString()}
                                        totalServings={product.product_servings_total}
                                        display="none"
                                        mt={1}
                                    />
                                    <CostPerCount
                                        price={subscriptionDiscounts.adjustedPrice.toString()}
                                        count={product.product_size}
                                        unitOfMeasure={product.product_serving_unit_of_measure}
                                        display="none"
                                        mt={1}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </RadioButton>
                <RadioButton value="single">
                    <Box display="flex">
                        <Box flex="1">
                            <Text color="grays.4" mb={1}>
                                Purchase this time only
                            </Text>
                            {isOnSale && (
                                <Text display="inline-block" textDecoration="line-through" color="grays.4" mr={2}>
                                    {product.price_msrp}
                                </Text>
                            )}
                            <Text display="inline-block" fontWeight="bold">
                                {product.price}
                            </Text>
                            {isOnSale && (
                                <Text display="inline-block" fontWeight="bold" color="grays.4" ml={2}>
                                    {saleText}
                                </Text>
                            )}
                            {isSupplementsOrFoods && (
                                <>
                                    <CostPerServing
                                        price={product.price_raw}
                                        totalServings={product.product_servings_total}
                                        display="none"
                                        mt={1}
                                    />
                                    <CostPerCount
                                        price={product.price_raw}
                                        count={product.product_size}
                                        unitOfMeasure={product.product_serving_unit_of_measure}
                                        display="none"
                                        mt={1}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </RadioButton>
            </RadioGroup>
        </Box>
    );
};

export default SubscriptionEligible;
