import PromoDiscountConfig, { PromoDiscountTypeEnum } from "../@types/interfaces/PromoDiscountConfig";

interface SubscriptionProduct {
    productId: number;
    msrp: number;
    price: number;
}

export interface SubscriptionDiscounts {
    discount: number;
    rebillDiscount: number;
    adjustedPrice: number;
    rebillAdjustedPrice: number;
    ratio: number;
    rebillRatio: number;
    percent: number;
    rebillPercent: number;
    applies: boolean;
}

export default class SubscriptionDiscountCalculator {
    public readonly DEFAULT_RATIO = 0.15;

    getDiscounts = ({ productId, msrp, price}: SubscriptionProduct, promos: PromoDiscountConfig[] = []): SubscriptionDiscounts => {
        const promo = this.getEligiblePromo(productId, promos);
        let [ratio, rebillRatio] = this.getDiscountRatios(promo);
        let adjustedPrice: number;
        // Determine if the discount applies to MSRP or Price (usually MSRP). In other words, if it stacks with the sale discount.
        // Rebill discount always applies to MSRP.
        const discountFromMsrp = promo?.promo_discounts_type !== PromoDiscountTypeEnum.SUBSCRIPTION;
        if (discountFromMsrp) {
            adjustedPrice = msrp * (1 - ratio);
        } else {
            adjustedPrice = price * (1 - ratio);
            // Recalculate the ratio as if it were off MSRP. Ratio will increase if the item is on sale.
            ratio = 1 - (adjustedPrice / msrp);
        }

        const saleRatio = 1 - (price / msrp);
        const rebillDiscount = msrp * rebillRatio;
        return {
            discount: msrp - adjustedPrice,
            rebillDiscount,
            adjustedPrice,
            rebillAdjustedPrice: msrp - rebillDiscount,
            ratio,
            rebillRatio,
            percent: Math.round(ratio * 100),
            rebillPercent: Math.round(rebillRatio * 100),
            applies: !discountFromMsrp || ratio > saleRatio,
        };
    };

    private getEligiblePromo(productId: number, promos: PromoDiscountConfig[]): PromoDiscountConfig | undefined {
        return promos.find((promo) => this.isSubscriptionPromo(promo)
            && (promo.eligible_promo_product_ids.includes(productId)
                || promo.eligible_discount_product_ids.includes(productId)));
    }

    private isSubscriptionPromo = (promo: PromoDiscountConfig): boolean => (
        [PromoDiscountTypeEnum.SUBSCRIPTION, PromoDiscountTypeEnum.SUBSCRIPTION_MSRP].includes(promo.promo_discounts_type)
    );

    private getDiscountRatios(promo: PromoDiscountConfig | undefined): [number, number] {
        return [
            promo?.unit_discount_ratio ?? this.DEFAULT_RATIO,
            promo?.subscription_rebill_discount_ratio ?? this.DEFAULT_RATIO,
        ];
    }
}
